








































import {Vue, Component, Prop} from "vue-property-decorator";
import IsFriendContType from "@/views/Friend/IsFriend/components/Cont/indexType";

@Component({name: "IsFriendCont"})
export default class IsFriendCont extends Vue implements IsFriendContType{
    rightIcon = require("@/assets/icon/Basics/Right.png")

    handleToPath(){
        this.$router.push({
            name:"myFriendCircle",
            query:{
                id:this.getData && this.getData.id,
                name:this.getData && this.getData.name || this.getData.nickName
            }
        })
    }

    get NotShow(){
        if ( this.getData && Boolean(this.getData.momentImg) ){
            if ( String(this.getData.momentImg).length ){
                return false
            }else{
                return true
            }
        }else{
            return true
        }
    }

    get ImgSrc(){
        if ( this.getData && Boolean(this.getData.momentImg) ){
            if ( this.getData && String( this.getData.momentImg ).length ){
                let StrArr = String( this.getData.momentImg )
                let Arr = StrArr.split(",")
                let List = Arr.splice(0,3)
                List.forEach( ( item,index )=>{
                    if ( String(item) === "" ){
                        List.splice(index,1)
                    }
                } )
                return List
            }else{
                return  []
            }
        }else{
            return  []
        }
    }

    get getSign(){
        if ( this.getData && Boolean(this.getData.personSign) ){
            if ( this.getData.personSign.length ){
                return this.getData.personSign;
            }else{
                return "暂未留下个性签名"
            }
        }else{
            return "暂未留下个性签名"
        }
    }

    get searchState(){
        return "手机号搜索"
    }

    @Prop(Object)
    data!:any
    get getData(){ return this.data }
}
