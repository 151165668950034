





























import {Vue, Component} from "vue-property-decorator";
import IsFriendType from "@/views/Friend/IsFriend/IsFriend";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import IsFriendUser from "@/views/Friend/IsFriend/components/User/index.vue";
import IsFriendCont from "@/views/Friend/IsFriend/components/Cont/index.vue";
import {testData} from "@/views/Friend/IsFriend/Model";
import {disposeApplyFriend, getIsFriends} from "@/views/Friend/IsFriend/Server";
import ZoomPage from "@/impView/PageSafety";
import Storage from "@/util/Storage"

@Component({name: "IsFriend",components:{ IsFriendUser,HeadTop,IsFriendCont }})
export default class IsFriend extends ZoomPage implements IsFriendType{
    data = testData
    loadingShow = true

    mounted(){
        this.handleUpData()
    }

    handleClick(){
        if ( this.NewFriendsState ){
            this.$router.push({
                name:"chat",
                query:{
                    id:this.data.id + '',
                    title:this.data.name || this.data.nickName || '',
                    state:"chat"
                }
            })
        }else{
            this.$router.push({
                name:"applyFriend",
                query:{
                    data:JSON.stringify( this.data )
                }
            })
        }
    }

    handleClickState(state:number){
        disposeApplyFriend(this.data.id,state).then(res=>{
            this.handleUpData()
        })
    }

    handleUpData(): void {
        let { id = 0 } = this.$route.query
        getIsFriends( id as number ).then(res=>{
            this.loadingShow = false
            this.data = res
        })
    }

    get title(){
        // eslint-disable-next-line
        // @ts-ignore
        if ( this.data && Number( this.data.isFriends ) ){
            return "好友信息"
        }else{
            return "添加好友"
        }
    }

    get CartText(){
        // eslint-disable-next-line
        // @ts-ignore
        if ( this.data && Number( this.data.isFriends ) ){
            return "聊天"
        }else{
            return "添加好友"
        }
    }

    get NewFriendsState(){
        // 判断是否是好友
        // eslint-disable-next-line
        // @ts-ignore
        return this.data && Number( this.data.isFriends ) && true || false
    }

    get IsShowMy(){
        // 判断是否是 查看自己
        const userId = Storage.GetData_?.("userId")
        return this.data && this.data.id == userId && false || true;
    }
}
