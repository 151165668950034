























import {Vue, Component, Prop} from "vue-property-decorator";
import IsFriendUserType from "@/views/Friend/IsFriend/components/User/indexType";

@Component({name: "IsFriendUser"})
export default class IsFriendUser extends Vue implements IsFriendUserType{

    get getPhoto(){
        return this.getData && this.getData.headImg || require("@/assets/icon/Basics/default.png")
    }

    get getSex(){
        if ( this.getData && this.getData.sex == 1 ){
            return require("@/assets/icon/Basics/sex.png")
        }else{
            return require("@/assets/icon/Basics/sex1.png")
        }
    }

    @Prop(Object)
    data!:any
    get getData(){ return this.data }
}
